import React, {useContext, useEffect} from 'react';
import $ from 'jquery';
import { Row, Col } from 'react-grid-system';
import { Context } from '../context/Context';

import "../css/sections/about.css";

import Base from '../config'

export default function About() {
    const {setPage} = useContext(Context)
    useEffect(() => {
        setTimeout(function() {
            $(".bg-slider").css({"left":"-100vw"});
        }, 500);
        setPage("about")
      },[setPage])
    return (
        <>
            <section className='container-pdd mr-t-70'>
                <div>
                    <h1 className='main-head main-heading'><span className='b'>About Us</span></h1>
                </div>
                <div className='mr-t-10 flex-right'>
                    <p className='main-para main-line'>With years of experience and a passion for excellence, we specialize in creating custom software tailored to your unique needs.</p>
                </div>
            </section>

            <section className='container-pdd sec-mr-t'>
                <div>
                    <img className='w100' src={Base.pro1} alt='project' />
                </div>
            </section>

            <section className='container-pdd sec-mr-t'>
                <div>
                    <Row>
                        <Col sm={6}><div><p className='descript'>Our Vision</p></div></Col>
                        <Col sm={6}>
                            <div>
                                <p className='des-data'>To deliver exceptional software solutions that drive business growth and exceed customer expectations.</p>
                                <p className='des-data'>To be recognized as a trusted partner for software development and object design, known for our expertise, reliability, and commitment to excellence.</p>
                            </div></Col>
                    </Row>
                </div>
            </section>

            <section className='container-pdd sec-mr-t2'>
                <div><p className='descript'>Team</p></div>
                <Row>
                    <Col className='grid-mr-t' sm={4}>
                        <div>
                            <img className='w100' src={Base.pro2} alt='project SS'/>
                            <p className='sm-name-head'>Sangam Nayak</p>
                            <p className='sm-name-p'>Co-Founder</p>
                        </div>
                    </Col>
                    <Col className='grid-mr-t' sm={4}>
                        <div>
                            <img className='w100' src={Base.pro2} alt='project SS'/>
                            <p className='sm-name-head'>Siddhartha pradhan </p>
                            <p className='sm-name-p'>Co-Founder</p>
                        </div>
                    </Col>
                    <Col className='grid-mr-t' sm={4}>
                        <div>
                            <img className='w100' src={Base.pro2} alt='project SS'/>
                            <p className='sm-name-head'>vatsalya khobragade</p>
                            <p className='sm-name-p'>Co-Founder</p>
                        </div>
                    </Col>
                </Row>
            </section>

        </>
    )
}
