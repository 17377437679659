import React from 'react'
import { Container, Row, Col } from 'react-grid-system';

//css
import "../../css/sections/featuredclients.css";

export default function featuredClients() {
    let featured = [
        {"client":"Client 1", "type":"UI/UX", "details":"Tenetur quod quidem in voluptatem corporis dolorum dicta sit pariatur porro quaerat autem ipsam odit quam beatae tempora quibusdam illum! Modi velit odio nam nulla unde amet odit pariatur at!"},
        {"client":"Client 2", "type":"Furniture Design", "details":"Ad dolore dignissimos asperiores dicta facere optio quod commodi nam tempore recusandae. Rerum sed nulla eum vero expedita ex delectus voluptates rem at neque quos facere sequi unde optio aliquam!"},
        {"client":"Client 3", "type":"Branding", "details":"Consequatur rerum amet fuga expedita sunt et tempora saepe? Iusto nihil explicabo perferendis quos provident delectus ducimus necessitatibus reiciendis optio tempora unde earum doloremque commodi laudantium ad nulla vel odio?"}
    ]
    return (
        <>
            <div className='mr-after-heading'>
                {featured.map((fea, index) => (
                   <div className='featured-container'>
                   <Row>
                    <Col sm={4}>
                        <div className='featured-left'><p>{fea.client}</p></div>
                    </Col>
                    <Col sm={4}>
                        <div className='featured-mid'><p>{fea.type}</p></div>
                    </Col>
                    <Col sm={4}>
                        <div className='featured-right'><p>{fea.details}</p></div>
                    </Col>
                </Row> 
                {/* divider line */}
                {featured.length === index + 1 || (
                        <div className='featured-hr'></div>
                    )}
                </div>
                ))}
            </div>
        </>
    )
}
