import React, { useState } from 'react';
import './textInput.css';

export default function EmailInput({ value, onChange, placeholder }) {
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern

    if (!emailPattern.test(email)) {
      setErrorMessage('Please enter a valid email address');
    } else {
      setErrorMessage('');
    }
    onChange(e);
  };

  return (
    <div className='inputDiv'>
      <input
        className={`textInput ${errorMessage ? 'invalid' : ''}`}
        type="email"
        value={value}
        onChange={handleEmailChange}
        placeholder={placeholder}
        required
      />
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
}
