import React, { useState } from 'react';
import './textInput.css';

export default function MobileInput({ value, onChange, placeholder }) {
  const [errorMessage, setErrorMessage] = useState('');

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    const phonePattern = /^[0-9]{10,15}$/; // Validates a number with 10 to 15 digits

    if (!phonePattern.test(phoneNumber)) {
      setErrorMessage('Please enter a valid phone number (10-15 digits)');
    } else {
      setErrorMessage('');
    }

    onChange(e);
  };

  return (
    <div className='inputDiv'>
      <input
        className={`textInput ${errorMessage ? 'invalid' : ''}`}
        type="tel" // Changed to "tel" for better mobile experience
        value={value}
        onChange={handlePhoneNumberChange}
        placeholder={placeholder}
        required
      />
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
}
