import React from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom'

//imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/carousle.css";

//main data
import Pros from '../../projectsImg';

import SCthumb from "../../images/projects/shreeclasses/scthumb.png";
import DINEthumb from "../../images/projects/dineflow/dinethumb.png";
import BANJARAthumb from "../../images/projects/banjara/banjarathumb.png";

export default function carousle1() {
    let Thumbs = [{ "id": 1, "img": SCthumb }, { "id": 2, "img": DINEthumb }, { "id": 3, "img": BANJARAthumb }]

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        autoplay: true,
        slidesToShow: 2,
        speed: 2000,
        cssEase: "linear"
    };

    return (
        <>
            <div className="slider-container mr-after-heading">
                <Slider {...settings}>
                    {Pros.json.projects.map((project, index) => (
                        <div>
                            <Link to={project.link}>
                                <div className='pro-car-div'>
                                    <div className=''>
                                        <img className='pro-car-img' src={Thumbs[Thumbs.findIndex(thumb => thumb.id === project.id)].img} alt={project.name} />
                                    </div>
                                    <div className='pro-title-div flex-align space-between'>
                                        <div className='pro-title-left'>{project.name}</div>
                                        <div className='pro-title-right'>{project.type}</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    )
}
