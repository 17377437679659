import React, { createContext, useState, useEffect } from 'react';

export const Context = createContext();

export const Provider = ({ children }) => {
  const data = "this is testing data"
  const [page, setPage] = useState("");
  const coming = false;

  return (
    <Context.Provider value={{ data, page, setPage, coming }}>
      {children}
    </Context.Provider>
  );
};
