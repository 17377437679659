//shree classes
import SCtumb from "./images/projects/shreeclasses/scthumb.png";
import SCbanner from "./images/projects/shreeclasses/scbanner.png";
import SC1 from "./images/projects/shreeclasses/sc1.png";
import SC2 from "./images/projects/shreeclasses/sc2.png";
import SC3 from "./images/projects/shreeclasses/sc3.png";
import SC4 from "./images/projects/shreeclasses/sc4.png";
import Json from "./images/projects/prosJson.json";

const Pros = {
    json: Json,
    sctumb: SCtumb,
    scbanner: SCbanner,
    sc1: SC1,
    sc2: SC2,
    sc3: SC3,
    sc4: SC4
}

export default Pros;