import React from 'react'
import { Link } from 'react-router-dom'
import "../../css/carousle.css";
import "./projectCard.css";

//ptojects
import SCthumb from "../../images/projects/shreeclasses/scthumb.png";
import DINEthumb from "../../images/projects/dineflow/dinethumb.png";
import BANJARAthumb from "../../images/projects/banjara/banjarathumb.png";

export default function projectCard(prop) {
    let Thumbs = [{"id" : 1, "img": SCthumb }, {"id" : 2, "img": DINEthumb }, {"id" : 3, "img": BANJARAthumb }]
    return (
        <Link to={prop.Link}>
            <div className='pro-car-div pro-card-div'>
                <div className=''>
                    <img className='pro-car-img' src={Thumbs[Thumbs.findIndex(thumb => thumb.id === prop.Id)].img} alt="img"/>
                </div>
                <div className='pro-title-div flex-align space-between'>
                    <div className='pro-title-left'>{prop.Name}</div>
                    <div className='pro-title-right'>{prop.Type}</div>
                </div>
            </div>
        </Link>
    )
}
