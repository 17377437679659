import React, {useEffect, useContext} from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { Context } from '../context/Context';

//css
import "../css/section.css";
import "../css/projects/project.css";

//projects
import Pros from '../projectsImg';
import SCbanner from '../images/projects/shreeclasses/scbanner.png';
import SC1 from '../images/projects/shreeclasses/sc1.png';
import SC2 from '../images/projects/shreeclasses/sc2.png';
import SC3 from '../images/projects/shreeclasses/sc3.png';
import SC4 from '../images/projects/shreeclasses/sc4.png';

import BanjaraBanner from "../images/projects/banjara/banjarabanner.png";
import Banjara1 from "../images/projects/banjara/banjara1.png";
import Banjara3 from "../images/projects/banjara/banjara3.png";
import Banjara4 from "../images/projects/banjara/banjara4.png";

import DineFlowBanner from "../images/projects/dineflow/dineflowbanner.png";
import DineFlow1 from "../images/projects/dineflow/dineflow1.png";
import DineFlow2 from "../images/projects/dineflow/dineflow2.png";
import DineFlow3 from "../images/projects/dineflow/dineflow3.png";

export default function Projects() {
    const { setPage } = useContext(Context);
    const { id } = useParams();
    console.log(id)
    console.log(typeof(id))
    let imgs = [{"id" : 1, "img" : [SC1, SC2, SC3, SC4], "banner": SCbanner}, {"id" : 2, "img" : [DineFlow1, DineFlow2, DineFlow3], "banner": DineFlowBanner}, {"id" : 3, "img" : [Banjara1, Banjara3, Banjara4], "banner": BanjaraBanner}]
    let project = Pros.json.projects
    let pos = project.findIndex(project => `${project.id}` === id)
    useEffect(() => {
        setTimeout(function() {
            $(".bg-slider").css({"left":"-100vw"});
        }, 500);
    setPage("works")
  })
    return (
        <>
            <section className='container-pdd mr-t-70'>
                <div>
                    <h1 className='main-head main-heading'><span className='b'>{project[pos].name}</span></h1>
                </div>
                <div className='mr-t-10 flex-right'>
                    <p className='main-para main-line'>{project[pos].sortDetails}</p>
                </div>
            </section>

            <section className='container-pdd sec-mr-t'>
                <div>
                    <img className='w100' src={imgs[pos].banner} alt='project' />
                </div>
            </section>

            <section className='container-pdd sec-mr-t'>
                <div>
                    <Row>
                        <Col sm={6}><div><p className='descript'>Description</p></div></Col>
                        <Col sm={6}><div><p className='des-data'>{project[pos].description}</p></div></Col>
                    </Row>
                </div>
            </section>

            {/* <section className='container-pdd sec-mr-t2'>
                <div><p className='descript'>Production Drawing</p></div>
                <Row>
                    <Col className='grid-mr-t' sm={6}><img className='w100' src={Base.pro2} alt='project SS'/></Col>
                    <Col className='grid-mr-t' sm={6}><img className='w100' src={Base.pro2} alt='project SS'/></Col>
                </Row>
            </section> */}

            <section className='container-pdd sec-mr-t2'>
                <div><p className='descript'>Project Images</p></div>
                <Row>
                    {
                        imgs[pos].img.map((i,index)=>(
                            <Col className='grid-mr-t' sm={12}><img className='w100' src={i} alt='project SS'/></Col>
                        ))
                    }
                </Row>
            </section>

        </>
    )
}
