import React, { useState } from 'react';

//css
import "./dropoption.css";

import DropIcon from "../../images/icons/arrow-down.png";

export default function DropOption(props) {
    const [dropAc, setDropAc] = useState(false);
    const options = props.options;
    const dropHeight = (options.length * 3.3) + 0.8;

    const toggleDrop = () => {
        setDropAc(prev => !prev);
    };
    

    return (
        <div className='drop-div flex relative'>
            <div className='drop-opt-btn flex-align pointer' onClick={toggleDrop}>
                <p className='drop-opt-title'>{props.title}</p>
                <img className='drop-opt-icon' style={{
                    transform: dropAc ? 'rotate(-180deg)' : 'rotate(0deg)'
                }} src={DropIcon} alt="Drop Icon" />
            </div>
            <div 
                className='drop-opts'
                style={{
                    padding: dropAc ? '0.4vw 0.8vw' : '0',
                    height: dropAc ? `${dropHeight}vw` : '0'
                }}
            >
                {options.map((opt, index) => (
                    <div className='drop-opt pointer' key={index} onClick={()=>{setDropAc(false)}}>
                        <p className='drop-opt-title'>{opt}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
