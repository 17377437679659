import React from 'react'
import "./textInput.css"

export default function textInput({ value, onChange, placeholder }) {
  return (
    <div className='inputDiv'>
        <input
            className='textInput'
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required
        />
    </div>
  )
}
