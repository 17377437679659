import React from 'react'
import { Link } from 'react-router-dom'
import "./bunDark.css";

export default function btnDark(props) {
  return (
    <div className='btn-act-div flex-center' style={{ marginTop: props.mr }}>
      <Link to={props.link}>
        <div className='btn-act-dark center pointer'>
            <p className='title'>{props.title}</p>
        </div>
      </Link>
    </div>
  )
}
