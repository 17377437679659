import React from 'react'
import { Oval } from 'react-loader-spinner'
import Base from '../config';
import "../css/preloader.css";

export default function Priloader() {
    return (
        <div className='preloader-box flex-all-center'>
            <div className='loading-pkg flex-all-center'>
                <img className='loader-img' src={Base.logoW} alt='concept hands' />
                <div className="loader-oval">
                    <Oval
                        visible={true}
                        width="100%"
                        height="100%"
                        color="#ffffff"
                        secondaryColor="#000000"
                        ariaLabel="oval-loading"
                        strokeWidth="1.2"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            </div>
        </div>
    )
}
