import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system';
import Base from '../config';

//css
import "../css/footer.css";


export default function Footer() {
    return (
        <>
            <section className='container-pdd sec-mr-t'>
                <div className='flex-center'>
                    <img className='nrp' src={Base.nrp} alt='anime' />
                </div>
            </section>

            <footer className='sec-mr-t'>
                <div className='container2'>
                    <p className='center footer-header'>LET'S WORK TOGETHER</p>
                </div>
                {/*  */}
                <div className='container2 flex-all-center'>
                    <Link to="/contact">
                        <div className='foot-contact-btn pointer'>
                            <p>CONTACT US</p>
                        </div>
                    </Link>
                </div>
                {/*  */}
                <div className='container2 footer-links'>
                    <Row>
                        <Col sm={4}>
                            <div className='flex-left'>
                                <div className='footer-link-group'>
                                    <div className='footer-link'><a href='#'>FACEBOOK</a></div>
                                    <div className='footer-link'><a href='#'>INSTAGRAM</a></div>
                                    <div className='footer-link'><a href='#'>TWITTER</a></div>
                                    <div className='footer-link'><a href='#'>ORIBBBLE</a></div>
                                    <div className='footer-link'><a href='#'>LINKEDIN</a></div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className='flex-center'>
                                <div className='footer-link-group'>
                                    <div className='footer-link'><a href='#'>N/2 AVANTI VIHAR, TELIBANDHA, RAIPUR, C.G.</a></div>
                                    <div className='footer-link'><a href='#'>PLOT NO. 761, MAHARISHI COLLEGE RD, NEAR FRANKFINN INSTITUTE, SAHEED NAGAR, BHUBANESWAR, ODISHA 751007, INDIA</a></div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className='flex-right'>
                                <div className='footer-link-group'>
                                    <div className='footer-link'><a href='mailto:contact@concepthands.com'>CONTACT@CONCEPTHANDS.COM</a></div>
                                    <div className='footer-link'><a href='tel:+91626625558'>+91 6266-25558</a></div>
                                    <div className='footer-link'><a href='tel:+917894199702'>+91 78941-99702</a></div>
                                    <div className='footer-link'><a href='tel:+919753141169'>+91 97531-41169</a></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/*  */}
                <div className='container2'>
                    <hr className='hr1-white' />
                    <p className='copyright center'>COPYRIGHT | CONCEPTHANDS.COM</p>
                </div>
            </footer>
        </>
    )
}
