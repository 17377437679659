import React, { useEffect, useContext } from 'react';
import $ from 'jquery';
import { Row, Col } from 'react-grid-system';
import { Context } from '../context/Context';

import Dropoption from '../components/dropdown/dropoption';
import ProjectCard from '../components/cards/projectCard';
import "../css/projects.css";

//projects
import Pros from '../projectsImg';

const Works = () => {
  const { setPage } = useContext(Context);
  useEffect(() => {
    console.log(Pros.json.projects)
    setTimeout(function() {
      $(".bg-slider").css({"left":"-100vw"});
  }, 500);
    setPage("works")
  },[setPage])
  return (
    <div>
      <section className='container-pdd mr-t-70'>
        <div>
          <h1 className='main-head main-heading'><span className='b'>our works</span></h1>
        </div>
        <div className='mr-t-10 flex-right'>
          <p className='main-para main-line'>Discover our portfolio to see how we turn ideas into stunning designs. From custom furniture to dynamic digital solutions, each project showcases our commitment to quality and creativity.</p>
        </div>
      </section>

      <section className='container-pdd sec-mr-t'>
        <Dropoption title="All Projects" options={["ui/ux design", "web development", "home decor", "3d modeling", "window display", "logo design"]} />
        <Row>
          {Pros.json.projects.map((project, index) => (
            <Col sm={6} key={index}>
              <div className='project-card-mrt'>
                <ProjectCard Name={project.name} Type={project.type} Id={project.id} Link={project.link} />
              </div>
            </Col>
          ))}
        </Row>
      </section>

    </div>
  );
};

export default Works;
