import React, { useContext, useEffect } from 'react';
import $ from 'jquery';
import Countdown from 'react-countdown';
import { Context } from '../context/Context';

//image
import Base from '../config';

//css
import "../css/section.css";
import "../css/animations/nrp.css";

//components
import Heading from '../components/text/heading';
import Carousle1 from '../components/carousle/carousle1';
import BtnDark from '../components/button/btnDark';
import OurServices from '../data/home/ourServices';
import FeaturedClients from '../data/home/featuredClients';

const Home = () => {
  const launchDate = new Date();
  launchDate.setDate(launchDate.getDate() + 20);

  $("Link").click(function(){
    $(".bg-slider").css({"left":"100vh"})
  })

  const { setPage, coming } = useContext(Context);
  useEffect(() => {
    setTimeout(function() {
      $(".bg-slider").css({"left":"-100vw"});
  }, 500);
    setPage("home")
  })
  return (
    <>
      {coming ?
        <div className='flex-all-center vw100 vh100'>
          <div className="coming-soon">
            <img src={Base.logoB} alt="Concept Hands Logo" className="logo" />
            <h1>Concept Hands</h1>
            <p>Our website is coming soon. Stay tuned!</p>
            <Countdown date={launchDate} />
            <p>Contact us at: <a href="mailto:CONTACT@CONCEPTHANDS.COM">CONTACT@CONCEPTHANDS.COM</a></p>
          </div>
        </div>
        : <div>

          <section className='container-pdd mr-t-70'>
            <div>
              <h1 className='main-head main-heading'><span className='b'>DESIGNING</span> Spaces <span className='b'>&</span> EXPERIENCES</h1>
            </div>
            <div className='mr-t-10 flex-right'>
              <p className='main-para main-line'>At Concept Hands, we craft exceptional furniture and digital solutions that redefine your surroundings. </p>
            </div>
          </section>

          <section className='container-pdd sec-mr-t'>
            <Heading text="Selected Works" />
            <Carousle1 />
            <BtnDark title="All Works" link="/works" />
          </section>

          <section className='container-pdd sec-mr-t'>
            <Heading text="Our Services" />
            <OurServices />
          </section>

          <section className='container-pdd sec-mr-t'>
            <Heading text="Featured Clients" />
            <FeaturedClients />
          </section>

        </div>
      }
    </>
  );
};

export default Home;
