import React from 'react'

export default function textareaInput({ value, onChange, placeholder }) {
  return (
    <div className='inputDiv'>
        <textarea rows={6} 
            className='textInput textareaInput'
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}></textarea>
    </div>
  )
}
