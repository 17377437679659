import React from 'react'
import { Container, Row, Col } from 'react-grid-system';

//css
import "../../css/sections/ourservices.css";

export default function ourServices() {
    let servicelist = [
        { "service": "Digital Services", "serv": ["UI/UX Design", "Web Development", "App Development", "SEO", "Shopify Development", "Wordpress Development"] },
        { "service": "Physical Services", "serv": ["3D Concepts", "Furniture Design", "Textile Print", "Lighting Design", "Bag Design"] },
        { "service": "Branding & Communication", "serv": ["Logo Design", "Visiting Card", "Photo & Video Editing"] },
        { "service": "Space Design", "serv": ["Exhibition Booth Design", "Visual Merchandising", "Window Display Design"] }
    ]
    return (
        <>
            {servicelist.map((srv, index) => (
                <div className='mr-after-heading our-services'>
                    <Row>
                        <Col sm={6}>
                            <div className='service-left'>
                                <p className='service-left-heading'>{srv.service}</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className='service-right'>
                                <p className='service-right-services'>
                                    {srv.serv.map((s, index) => (
                                        <span>{s}</span>
                                    ))}
                                    {/* UI/UX Design <span></span> Web Development <span></span> App Development <span></span> SEO <span></span> Shopify Development <span></span> Wordpress Development */}
                                </p>
                            </div>
                        </Col>
                    </Row>

                    {/* divider line */}
                    {servicelist.length === index + 1 || (
                        <div className='service-hr'></div>
                    )}
                </div>
            ))}
        </>
    )
}
