import Logo from "./logo.png";
import LogoB from "./images/logo-b.png";
import LogoW from "./images/logo-w.png";
import Nrp from "./images/nrp.png";

//projects
import Pro1 from "./images/projects/projectDemoImg.png";
import Pro2 from "./images/projects/projectDemoImg2.png";

const Base = {
    logo: Logo,
    logoB: LogoB,
    logoW: LogoW,
    nrp: Nrp,
    pro1: Pro1,
    pro2: Pro2
}

export default Base;