import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { Row, Col } from 'react-grid-system';
import { Context } from '../context/Context';

import emailjs from '@emailjs/browser';

import TextInput from '../components/inputs/textInput';
import EmailInput from '../components/inputs/emailInput';
import MobileInput from '../components/inputs/mobileInput';
import TextareaInput from '../components/inputs/textareaInput';
import SelectInput from '../components/inputs/selectInput';
// import BtnDark from '../components/button/btnDark';
import SubmitBtn from '../components/inputs/submitBtn';

export default function Contact() {
  const { setPage } = useContext(Context);
  const [ name, setName ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ mobile, setMobile ] = useState("")
  const [ msg, setMsg ] = useState("")
  const [ selopt, setSelOpt ] = useState("")

  const sendmail = () => {
    const templateParams = {
        name: "Name",
        phone: "1234567890",
        email: "email@email.com",
        sercice: "Web Service",
        message: "This is test from website"
    };

    emailjs
        .send('service_xa8se9i', 'template_fih1at2', templateParams, {
            publicKey: 'Ybcj_64CcdOQrut25',
        })
        .then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
            },
            (err) => {
                console.log('FAILED...', err);
            },
        );
}

  useEffect(()=>{
    setTimeout(function() {
      $(".bg-slider").css({"left":"-100vw"});
  }, 500);
    setPage("contact")
  },[setPage])

  return (
    <div>
      <section className='container-pdd mr-t-70'>
        <div>
          <h1 className='main-head main-heading'><span className='b'>Contact Us</span></h1>
        </div>
        <div className='mr-t-10 flex-right'>
          <p className='main-para main-line'>Have a software development project in mind or need expert object design services?<br/><br/> We're ready to help! Contact us today to discuss your vision and learn how our team can bring it to life. Whether you have a specific project in mind or simply need guidance, we're here to assist you. </p>
        </div>
      </section>
      <section className='container-pdd mr-t-70'>
         <Row>
          <Col sm={8}>
            <Row>
              <Col sm={6}>
                <TextInput value={name} placeholder={"*NAME"} onChange={()=>{setName(EventTarget.value)}}/>
              </Col>
              <Col sm={6}>
                <EmailInput value={email} placeholder={"*EMAIL"} onChange={()=>{setEmail(EventTarget.value)}}/>
              </Col>
              <Col sm={6}>
                <MobileInput value={mobile} placeholder={"*PHONE"} onChange={()=>{setMobile(EventTarget.value)}}/>
              </Col>
              <Col sm={6}>
                <SelectInput value={selopt} placeholder={"*SERVICES"} setval={setSelOpt} opt={["UI/UX design","Web development","Home decor","3D modeling","Window display","Logo design"]}/>
              </Col>
              <Col sm={12}>
                <TextareaInput value={msg} placeholder={"Message Us"} onChange={()=>{setMsg(EventTarget.value)}} />
              </Col>
            </Row>
            <SubmitBtn mr={0} title="Submit"/>
          </Col>
         </Row>
      </section>
    </div>
  )
}
