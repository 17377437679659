import React from 'react'

export default function submitBtn(props) {
  return (
    <button className='btn-act-div flex-center' style={{ marginTop: props.mr, padding: 0, border: "none", background: "transparent" }} onClick={()=>{console.log("submit button Clicked.")}}>
        <div className='btn-act-dark center pointer'>
            <p className='title'>{props.title}</p>
        </div>
    </button>
  )
}
